.about{
    margin-bottom: 12%;
    font-size: 60px;
}

@media only screen and (max-width: 1440px){
    .about{
        margin-bottom: 19%;
        font-size: 40px;
    }
}

@media only screen and (max-width: 1680px){
    .about{
        margin-bottom: 14%;
        font-size: 50px;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 1080px){
    .about{
        margin-bottom: 14.5%;
        font-size: 60px;
    }
    
}
