.pager{
    display: grid;
    grid-template-columns: 70% 30%; /* Defines two columns 1250px*/
    grid-template-rows: 0% 30%; /* Defines two rows */
    
}

div.Chessboard-Chesspage {
    display: grid;
    place-items: start;
    height: 100%; 
    margin-left: 5%;
    margin-top: 0.1%;
    margin-bottom: 1%;
}
.Timer{
    margin-top: 60%;
    justify-content: end
}

@media only screen and (max-width: 1440px){
    .pager{
        display: grid;
        grid-template-columns: 1100px 340px; 
        grid-template-rows: 110px 240px; 
    }
    .Timer{
        margin-left: 15%;
        margin-top: 90%;
        justify-content: end
    }
    div.Chessboard-Chesspage {
        display: grid;
        place-items: start;
        height: 100%; 
        margin-left: 5%;
        margin-top: 1.5%;
        margin-bottom: 1%;
    }
}

@media only screen and (max-width: 1680px){
    .pager{
        display: grid;
        grid-template-columns: 1180px 500px; 
        grid-template-rows: 100px 300px; 
    }

    .Timer{
        margin-left: 2%;
        margin-top: 60%;
        justify-content: end
    }

    div.Chessboard-Chesspage {
        display: grid;
        place-items: start;
        height: 100%; 
        margin-left: 5%;
        margin-top: 1.5%;
        margin-bottom: 1%;
    }
}

@media only screen and (max-width: 1920px) and (max-height: 1080px){
    .pager{
        display: grid;
        grid-template-columns: 1250px 500px; 
        grid-template-rows: 100px 200px; 
    }

    .Timer{
        margin-left: 2%;
        margin-top: 70%;
        justify-content: end
    }

    div.Chessboard-Chesspage {
        display: grid;
        place-items: start;
        height: 100%; 
        margin-left: 10%;
        margin-top: 1%;
        margin-bottom: 1%;
    }
}