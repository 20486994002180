#page{
    display: flex;
}

.Pie{
    margin-left: 3%;
}

@media only screen and (max-width: 1440px){
    .Pie{
        margin-left: 1%;
    }
}

@media only screen and (max-width: 1680px){
    .Pie{
        margin-left: 3%;
    }
}


@media only screen and (max-width: 1920px)  and (max-height: 1080px){
    .Pie{
        margin-left: 2%;
    }
    
}