#chessboard {
  display: grid;
  grid-template-columns: repeat(10, 100px);
  grid-template-rows: repeat(8, 100px);
  width: 1000px;
  height: 800px;
  background-color: blue;
}

.modal {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.modal.hidden {
  display: none;
}

.modal > .modal-body {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 400px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 300px;
  width: 800px;
  background-color: rgba(127, 127, 127, 0.8);
}

.modal > .modal-body > img {
  height: 120px;
  padding: 20px;
  border-radius: 50%;
}

.modal > .modal-body > img:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.modal > .modal-body > .checkmate-body {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.modal > .modal-body > .checkmate-body > span {
  font-size: 32px;
}

.modal > .modal-body > .checkmate-body > button {
  background-color: #779556;
  border: none;
  border-radius: 8px;
  padding: 24px 48px;
  font-size: 32px;
  color: white;
}

.modal > .modal-body > .checkmate-body > button:hover {
  cursor: pointer;
}

div button {
  margin-left: 175%;
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
}

div button img {
  width: 20px;
  height: 20px;
}

.theme-changer-hidden {
  display: none;
}
