.App{    
    height: 100%;
    width: 100%;
}
.App .dark-theme{
  background-color: #202020;
}

.App .light-theme{
  background-color: #fafafa;
}
